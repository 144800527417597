// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-note-js": () => import("./../src/pages/note.js" /* webpackChunkName: "component---src-pages-note-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("./../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

